import * as types from "../mutation-types";
import friendsAPI from "@/api/friends";

const state = {
  friends: [],
   friend: "",
};

const getters = {
  friends: (state) => state.friends,
};

const actions = {
  async getFriends({ commit }, userID) {
    try {
      const response = await friendsAPI.getFriends(userID);
      const friends = response.data;
      commit(types.SET_FRIENDS, { friends });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createFriend({ commit }, payload) {
    try {
      const response = await friendsAPI.createFriend(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async updateFriend({ commit }, payload) {
    try {
      const response = await friendsAPI.updateFriend(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  [types.SET_FRIENDS](state, { friends }) {
    state.friends = friends;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
