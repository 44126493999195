<template>
  <div class="col-sm-12 box-col-12">
    <div class="card">
      <div class="social-tab">
        <ul class="nav nav-tabs" id="top-tab" role="tablist">
          <li class="nav-item" v-on:click="changetab('timeline')">
            <a
              class="nav-link"
              :class="{ 'show active': showtab == 'timeline' }"
              id="top-timeline"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="timeline"
              aria-selected="false"
              ><vue-feather type="clock"></vue-feather>Mi feed</a
            >
          </li>
          <!-- <li class="nav-item" v-on:click="changetab('about')">
            <a
              class="nav-link"
              :class="{ 'show active': showtab == 'about' }"
              id="top-about"
              data-toggle="tab"
              role="tab"
              aria-controls="about"
              aria-selected="false"
              ><vue-feather type="alert-circle"></vue-feather>About
            </a>
          </li> -->
          <li class="nav-item" v-on:click="changetab('friends')">
            <a
              class="nav-link"
              :class="{ 'show active': showtab == 'friends' }"
              id="top-friends"
              data-toggle="tab"
              role="tab"
              aria-controls="friends"
              aria-selected="false"
              ><vue-feather type="users"></vue-feather>Amigos</a
            >
          </li>
          <li class="nav-item" v-on:click="changetab('photos')">
            <a
              class="nav-link"
              :class="{ 'show active': showtab == 'photos' }"
              id="top-photos"
              data-toggle="tab"
              role="tab"
              aria-controls="photos"
              aria-selected="false"
              ><vue-feather type="image"></vue-feather>Fotos</a
            >
          </li>
        </ul>
        <!-- <div class="input-group">
          <input
            class="form-control"
            type="text"
            placeholder="Search......."
          /><span class="input-group-text"> <i class="fa fa-search"></i></span>
        </div> -->
        <button
          v-if="hasParams && !pending && !isFriend"

          @click="newFriend"
          class="btn btn-primary"
        >
          Agregar Amigo
        </button>
        <button v-if="hasParams && pending" class="custom-btn" disabled>
          Solicitud enviada
        </button>
        <button v-if="hasParams && isFriend" class="btn btn-primary">
          <i class="fa fa-user"></i> Amigos
        </button>
      </div>
    </div>
  </div>
  <div class="tab-content" id="top-tabContent">
    <div
      class="tab-pane fade"
      :class="{ 'show active': showtab == 'timeline' }"
      id="timeline"
      role="tabpanel"
      aria-labelledby="timeline"
    >
      <timeLine :friends="friends" :posts="posts" />
    </div>
    <!-- <div
      class="tab-pane fade"
      :class="{ 'show active': showtab == 'about' }"
      id="about"
      role="tabpanel"
      aria-labelledby="about"
    >
      <indexAbout />
    </div> -->
    <div
      class="tab-pane fade"
      :class="{ 'show active': showtab == 'friends' }"
      id="friends"
      role="tabpanel"
      aria-labelledby="friends"
    >
      <friendView />
    </div>
    <div
      class="tab-pane fade"
      :class="{ 'show active': showtab == 'photos' }"
      id="photos"
      role="tabpanel"
      aria-labelledby="photos"
    >
      <photos />
    </div>
  </div>
</template>

<style lang="scss">
.nav-item {
  cursor: pointer;
}

.custom-btn {
  background-color: #00a400;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: not-allowed;
}

.custom-btn:hover {
  background-color: #00a400;
}
</style>

<script>
import indexAbout from "./about/indexAbout.vue";
import timeLine from "@/components/socialapp/timeLine.vue";
import friendView from "./friends/friendView.vue";
import photos from "./photosView.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    indexAbout,
    timeLine,
    friendView,
    photos,
  },
  data() {
    return {
      showtab: "timeline",
      pending: false,
      isFriend: false,
    };
  },
  computed: {
    ...mapState({
      friends: (state) => state.friends.friends,
      posts: (state) => state.feed.posts,
    }),
    hasParams() {
      return Object.keys(this.$route.params).length > 0;
    },
  },
  async mounted() {
    const userId = localStorage.getItem("userId");
    const targetUserId = this.$route.params.id;

    if (targetUserId) { 
      await this.getFriends({ payload: { id: targetUserId } });
      const currentFriendship = this.friends.find(
        (friend) => friend.user_friend_id === userId
      );
      if (currentFriendship) {
        if (currentFriendship.status === "pending") {
          this.pending = true;
        } else if (currentFriendship.status === "approved") {
          this.isFriend = true;
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
      updateUser: "user/updateUser",
      createFriend: "friends/createFriend",
      getFriends: "friends/getFriends",
    }),
    changetab(tab) {
      this.showtab = tab;
    },
    async newFriend() {
      const data = {
        user_id: localStorage.getItem("userId"),
        user_friend_id: this.$route.params.id,
        status_of_friend: "pending",
      };
      try {
        await this.createFriend({ payload: data });
        this.pending = true;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
